<template>
  <div id="wrapper">
    <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
    <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
      <div id="content">
        <header-btn />
        <div class="container-fluid">
          <div class="d-sm-flex justify-content-between align-items-center mb-4">
            <h3 class="text-dark mb-0" > {{ employeeLangauge[language].employees }} </h3>
          </div>
          <hr>
          <div class="row">
            <button v-if="user.type == 'U' || user.type == 'A'"
              class="btn btn-success col-xl-2 col-md-5 mx-2 mb-2"
              data-toggle="modal"
              data-target="#mdl_employee"
              @click="clear_employee()"
            >
              {{ employeeLangauge[language].add_employee }} &nbsp;
              <i class="fa fa-plus"></i>
            </button>
            <input
              type="text"
              class="form-control col-xl-2 col-md-5 mx-2 mb-2"
              v-bind:placeholder="employeeLangauge[language].search + '...'"
              v-model="search"
              v-on:keyup.enter="search_employee()"
            >
            <button class="btn btn-success mx-1 mb-2" v-on:click="search_employee()"> <i class="fa fa-search"></i> </button>
            <button class="btn btn-primary mx-1 mb-2" v-on:click="refresh()"> <i class="fa fa-redo"></i> </button>
          <button class="btn btn-primary col-xl-2 col-md-5 mx-2 mb-2" data-toggle="modal" data-target="#mdlSearchByStaff">
              Search By Staff &nbsp;
              <i class="fa fa-search"></i>
            </button>
          <button class="btn btn-primary col-xl-2 col-md-5 mx-2 mb-2" data-toggle="modal" data-target="#mdlSort" v-if="user.type == 'A'">
              Sort Employees &nbsp; &nbsp;
              <v-icon color="white" class="ms-3"> mdi-sort </v-icon>
            </button>
          </div>
          <div class="row overflow-auto">
            <table class="table table-bordered table-striped text-center text-dark">
              <thead>
                <th>{{ employeeLangauge[language].id }}</th>
                <th>{{ employeeLangauge[language].first_name }}</th>
                <th>{{ employeeLangauge[language].last_name }}</th>
                <th>{{ employeeLangauge[language].staff }}</th>
                <th style="width: 10%;">{{ employeeLangauge[language].phone }}</th>
                <th style="width: 14%;">{{ employeeLangauge[language].registration_date }}</th>
                <th style="width: 10%;" class="px-0">{{ employeeLangauge[language].salary_type }}</th>
                <th style="width: 8%;" class="px-0">{{ employeeLangauge[language].status }}</th>
                <th style="width: 12.5%;" v-if="user.type == 'U' || user.type == 'A'">{{ employeeLangauge[language].actions }}</th>
              </thead>
              <tbody>
                <tr v-for="employee in employees" :key="employee.emp_id">
                  <td> {{ employee.emp_id }} </td>
                  <td> {{ employee.first_name }} </td>
                  <td> {{ employee.last_name }} </td>
                  <td> {{ employee.staff_name }} </td>
                  <td> {{ employee.phone }} </td>
                  <td> {{ organize_date(employee.reg_date) }} </td>
                  <td class="px-0"> {{ employee.salary_type == 'Monthly' ? employeeLangauge[language].monthly : employeeLangauge[language].daily }} </td>
                  <td class="px-0">
                      <span v-bind:class="{'text-danger': employee.active_status == 0, 'text-success': employee.active_status == 1}">{{employee.active_status | employees_status_filter(language) }}</span>
                  </td>
                  <td v-if="user.type == 'U' || user.type == 'A'">
                    <template >

                      <button class="btn btn-success mx-1 btn-sm" v-on:click="getEmployeeDocuments(employee.emp_id)" data-toggle="modal" data-target="#mdl_employee_documents">
                        <i class="fa fa-file"></i>
                      </button>
                      <button class="btn btn-primary mx-1 btn-sm" v-on:click="get_single_employee(employee.emp_id)" data-toggle="modal" data-target="#mdl_employee">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button class="btn btn-danger mx-1 btn-sm" v-on:click="deactive_employee(employee.emp_id)" v-if="employee.active_status==1">
                        <i class="fa fa-lock"></i>
                      </button>
                      <button class="btn btn-success mx-1 btn-sm" v-on:click="active_employee(employee.emp_id)" v-else-if="employee.active_status==0">
                        <i class="fa fa-unlock"></i>
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="float-right mb-3" id="paginations">
              <button class="btn btn-primary" v-on:click="previos_pagination_btn()">&lt;</button>

              <button class="btn btn-outline-primary mx-1 pagination-btns"
                      style="display: none" 
                      v-for="num in Math.ceil(noOfEmployees / 15)" :key="num"
                      v-bind:class=" 'pg-group-' + Math.ceil(num / 5) "
                      v-on:click="get_limited_data(num)"> {{ num }} </button>

              <button class="btn btn-primary" v-on:click="next_pagination_btn()">&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <change-pass/>

    <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_employee_documents">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn btn-danger" v-on:click="clearDocument()" data-dismiss="modal">&times;</button>
            <h5 class="mt-2">
              Employee Documents
              <button class="btn btn-primary btn-sm" @click="openUploadFile()">
                <i class="fas fa-plus"></i>
              </button>
              <button class="btn btn-success btn-sm ms-2" @click="downloadDocument()" v-if="selectedDocument.employee_document_id">
                <i class="fas fa-download"></i>
              </button>
              <input type="file" @change="proccessDocuments" ref="inputUploadFile" multiple accept="image/*, application/pdf" style="display: none;"/>
            </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid px-0">
              <div class="row">

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12" :style="`height: 450px; overflow-y: auto; border-right: 1px solid grey;`">
                  <div class="row p-0 mx-0 my-2"  v-for="d in documents" :key="'document' + d.employee_document_id">
                    <div class="col-8 px-0 m-0">
                      
                      <button :class="` btn btn-block btn-sm ${selectedDocument && d.employee_document_id == selectedDocument.employee_document_id ? 'btn-success' : 'btn-secondary'}`" @click="selectDocument(d)">
                        <i v-if="d.document_type == 'pdf'" class="fa fa-file-pdf"></i>
                        <i v-else class="fa fa-image"></i>
                        <span class="ms-1"> {{ d.employee_document_virtual_name }} </span>
                      </button>
                    </div>

                    <div class="col-2 pe-0 m-0">
                      <button class=" btn btn-primary btn-block btn-sm"  @click="editDocumentName(d)">
                        <i  class="fa fa-edit"></i>
                      </button>
                    </div>
                    <div class="col-2 pe-0 m-0">
                      <button class=" btn btn-danger btn-block btn-sm"  @click="deleteDocument(d)">
                        <i  class="fa fa-trash"></i>
                      </button>
                    </div>

                  </div>


                </div>

                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                  <template v-if="selectedDocumentFile">
                    <div>
                      <ViewPDF v-if="selectedDocument.document_type == 'pdf'" :download_file_name="selectedDocument.employee_document_virtual_name" :trigger_download="trigger_download" :src="documentUrl"  :customClass="'view-pdf'" :design="`border-radius: 3px; width: 96.5%; min-height: 450px; max-height: 450px; overflow-y: auto;`"/>
                      <viewer v-else  :options="{navbar: false, title: false, keyboard: false, fullscreen: true}">
                        <img :src="documentUrl" style="height: 450px;">
                      </viewer>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_employee">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn btn-danger" v-on:click="clear_employee()" data-dismiss="modal">&times;</button>
            
            <h5 class="mt-2"> {{ employeeLangauge[language].employee_information }} </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid pe-0">

              <div style="width: 100%;">
                <button class="btn btn-primary btn-block" data-toggle="collapse" data-target="#upload_image"> Employee Image </button>
              </div>
              <div class="collapse" id="upload_image">
                <div class="d-flex justify-content-center mt-1">
                  <v-img :src="personalImagePath" contain width="100px" height="250px" style="margin-bottom: 8px;"> </v-img>
                </div>

                <div style="width: 100%; text-align: center;" class="mt-1 mb-4">
                  <button class="mt-auto btn btn-primary btn-sm" @click="openPersonalImageInput">
                    Upload Image
                  </button>

                  <button class="mt-auto btn btn-danger btn-sm ms-3" @click="deleteImage()" v-if="single_employee.imageFile">
                    Delete Image
                  </button>

                  <input type="file" ref="personalImage" style="display:none;" @change="processImage">
                </div>
              </div>


              <div class="row mb-3 mt-3">
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].first_name }}:</label>
                <input
                  type="text"
                  class="form-control col-xl-4 mx-3"
                  v-model.trim="single_employee.first_name"
                >
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].last_name }}:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model.trim="single_employee.last_name" />
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].phone }}:</label>
                <input type="text" class="form-control col-xl-4 mx-3" maxlength="11" v-model="single_employee.phone">

                <label class="col-xl-1 mt-2 px-0"> Phone 2:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model="single_employee.phone2">
                
              </div>

              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0" >Family Number:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model="single_employee.family_number_1">

                <label class="col-xl-1 mt-2 px-0" >Family Number 2:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model="single_employee.family_number_2">
                
              </div>

              <div class="row mb-3">
                <!-- <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].staff }}:</label>
                <select class="form-control col-xl-4 mx-3" required v-model="single_employee.st_id">
                  <option v-for="staff in staffs" :key="staff.st_id" v-bind:value="staff.st_id">{{staff.staff_name}}</option>
                </select> -->
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].staff }}:</label>
                <select class="form-control col-xl-3 mx-3" required v-model="single_employee.st_id">
                  <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id">{{staff.staff_name}}</option>
                </select>
                <span class="col-1">
                  <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                </span>

              </div>
              
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].birth_date }}:</label>
                <input type="date" class="form-control col-xl-4 mx-3" v-model="single_employee.birth_date">

                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].salary_type }}:</label>
                <select class="form-control col-xl-4 mx-3" v-model="single_employee.salary_type">
                  <option value="Monthly"> {{ employeeLangauge[language].monthly }} </option>
                  <option value="Daily"> {{ employeeLangauge[language].daily }} </option>
                </select>
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> {{ employeeLangauge[language].country }}: </label>
                <select class="form-control col-xl-4 mx-3" v-model="single_employee.country">
                  <option value="1"> Iraq </option>
                  <option value="2"> Foreign </option>
                  <option value="3"> Iran </option>
                  <option value="4"> Bangladish </option>
                  <option value="5"> Syria </option>
                  <option value="6"> India </option>
                  <option value="7"> Pakistan </option>
                </select>

                <label class="col-xl-1 mt-2 px-0"> City: </label>
                <input class="form-control col-xl-4 mx-3" type="text" v-model="single_employee.city" />
                  
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> Job: </label>
                <input class="form-control col-xl-4 mx-3" type="text" v-model="single_employee.job" />

                <label class="col-xl-1 mt-2 px-0" style="font-size: 11.95pt;"> Blood Group </label>
                <input class="form-control col-xl-4 mx-3" type="text" v-model="single_employee.blood_group" />
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> Passport Number: </label>
                <input class="form-control col-xl-4 mx-3" type="text" v-model="single_employee.passport_number" />

                <label class="col-xl-1 mt-2 px-0">{{ employeeLangauge[language].expire_passport }}:</label>
                <input type="date" class="form-control col-xl-4 mx-3" v-model="single_employee.expiry_passport">
                

              </div>

              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> Accomodation Number: </label>
                <input class="form-control col-xl-4 mx-3" type="text" v-model="single_employee.accomodation_number" />

                <label class="col-xl-1 mt-2 px-0">Expire Accomodation:</label>
                <input type="date" class="form-control col-xl-4 mx-3" v-bind:disabled="single_employee.country == 1" v-model="single_employee.expire_accomodation">
              </div>

              <div class="row mb-3">

                <label class="col-xl-1 mt-2 px-0"> Cabina:</label>
                <select class="form-control col-xl-4 mx-3" required v-model="single_employee.cabina_id">
                  <option value="">  </option>
                  <option v-for="cabina in cabinas" :key="'c' + cabina.cabina_id" v-bind:value="cabina.cabina_id">{{cabina.cabina_name}}</option>
                </select>

                <label class="col-xl-1 mt-2 px-0">Asaish Code: </label>
                <input class="form-control col-xl-4 mx-3" v-model="single_employee.asaish_code">

              </div>

              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0" >Certificate:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model="single_employee.certificate">

                <label class="col-xl-1 mt-2 px-0"> First Work Date:</label>
                <input type="date" class="form-control col-xl-4 mx-3" v-model="single_employee.first_work_date">

              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> Car:</label>
                <input type="text" class="form-control col-xl-4 mx-3" maxlength="11" v-model="single_employee.car">
                
                <label class="col-xl-1 mt-2 px-0"> Car Number:</label>
                <input type="text" class="form-control col-xl-4 mx-3" maxlength="11" v-model="single_employee.car_number">
                
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0"> Location:</label>
                <input type="text" class="form-control col-xl-4 mx-3" v-model="single_employee.living_location">
                
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0">{{ employeeLangauge[language].food }} {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}: </label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.food_money">

                <label class="col-xl-1 mt-2 px-0">{{ employeeLangauge[language].transport }} {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}:</label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.transport_money">
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0">Cabina {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}: </label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.cabina_money">

                <label class="col-xl-1 mt-2 px-0">Expense {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}:</label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.expense_money">
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0">Fine {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}: </label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.fine_money">

                <label class="col-xl-1 mt-2 px-0">Loan {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}:</label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.loan_money">
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0">Accomodation {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}: </label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.accomodation_money">
              </div>
              <div class="row mb-3">
                <label class="col-xl-1 mt-2 px-0">Salary per month {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}: </label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.other_expense">

                <label class="col-xl-1 mt-2 px-0">Other Minus {{ single_employee.salary_type == 'Monthly' ? '($)' : '' }}:</label>
                <input type="number" min="0" class="form-control col-xl-4 mx-3" v-model="single_employee.other_minus">
              </div>
              
              <hr>
              <div class="row mb-3">
                <label class="col-xl-2 mt-2 text-center"> {{ employeeLangauge[language].monthly_salary }}:</label>
                <input type="number" min="0" class="form-control col-xl-2 text-center" v-bind:readonly="single_employee.salary_type == 'Daily'" v-model="single_employee.monthly_salary">

                <label class="col-xl-2 mt-2 text-center"> {{ employeeLangauge[language].daily_salary }}:</label>
                <input type="number" min="0" class="form-control col-xl-2 text-center" v-bind:readonly="single_employee.salary_type == 'Monthly'" v-model="single_employee.daily_salary">

                <label class="col-xl-2 mt-2 text-center"> {{ employeeLangauge[language].hour_salary }}:</label>
                <label
                  class="form-control col-xl-2 text-center"
                  style="background-color: rgba(128, 128, 128, 0.171);"
                > {{ single_employee.hour_salary }} </label>
              </div>
              <hr>
              <div class="row mb-3">
                <div class="col-xl-12 mb-2" v-if="single_employee.emp_id == null">
                  <button class="btn btn-success btn-block" id="btn_add_employee" v-on:click="add_employee()">
                    {{ employeeLangauge[language].add }} &nbsp;
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="col-xl-4 mb-2" v-if="single_employee.emp_id != null">
                  <button class="btn btn-warning btn-block" id="btn_update_employee" v-on:click="update_employee()">
                    {{ employeeLangauge[language].save_changes }} &nbsp;
                    <i class="fa fa-save"></i>
                  </button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlSearchByStaff">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn btn-danger" data-dismiss="modal">&times;</button>
            <h5 class="mt-2"> Search By Staff </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-lg-5 col-md-5">
                  <select class="form-control" v-model="searchStaffID">
                    <option value=""></option>
                    <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                  </select>
                </div>
                <span class="col-1">
                  <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                </span>
                <div class="col-12 col-lg-6 col-md-6">
                  <select class="form-control" v-model="searchStaffStatus">
                    <option value="1"> Actived </option>
                    <option value="0"> Deactived </option>
                  </select>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12 col-xl-3 col-lg-3 col-md-4 mb-2" v-for="employee in btnEmployees.filter(obj => obj.active_status == searchStaffStatus)" :key="employee.emp_id">
                  <button class="btn btn-block" :class="{'btn-primary': searchStaffStatus == '1', 'btn-danger': searchStaffStatus == '0'}" @click="searchByID(employee.emp_id)"> {{ employee.full_name }} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlSort">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn btn-danger" data-dismiss="modal">&times;</button>
            <h5 class="mt-2"> Sort Employees in Staff <button class="btn btn-primary ms-2" @click="saveEmployeesSort()"> <v-icon color="white">  mdi-content-save </v-icon> </button> </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <select class="form-control" v-model="searchStaffID">
                    <option value=""></option>
                    <option v-for="staff in staffs" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                  </select>
                </div>
              </div>
              <hr>
              <div style="width: 100%;">
                <draggable v-model="employees_to_sort">
                    <transition-group>
                      <v-btn block color="primary" class="mb-2" v-for="(employee, i) in employees_to_sort" :key="'emp' + i" > {{ employee.full_name }} </v-btn>
                    </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import auth from '../auth';
import $ from 'jquery';
import { Bus } from '../Bus.js';
import employeeLangauge from '../languages/employees.js';
import showError from '../showError';
import draggable from 'vuedraggable'
import ViewPDF from './View-PDF.vue'

const nullValues=[null,""];
export default {
  name: "Employees",
  components: {
    draggable,
    ViewPDF
  },
  data(){
      return{
        default_image: require('../assets/user2.jpg'),
        noOfEmployees: 0,
        employees:[],
        btnEmployees: [],
        single_employee:{
          emp_id: null,
          first_name: null,
          last_name: null,
          st_id: null,
          cabina_id: null,
          phone: null,
          birth_date: null,
          reg_date: null,
          salary_type: null,
          monthly_salary: null,
          daily_salary: null,
          hour_salary: null,
          identification_image_path: null,
          identification_image_file: null,
          personal_image_path: null,
          personal_image_file: null,
          staff_name: null,
          country: "1",
          city: null,
          food_money: 0,
          transport_money: 0,
          cabina_money: 0,
          fine_money: 0,
          expense_money: 0,
          loan_money: 0,
          accomodation_money: 0,
          other_expense: 0,
          other_minus: 0,
          expiry_passport: null,
          passport_number: null,
          expire_accomodation: null,
          accomodation_number: null,
          asaish_code: null,
          phone2: null,
          car: null,
          car_number: null,
          living_location: null,
          first_work_date: null,
          job: null,
          blood_group: null,
          imageFile: null,
          family_number_1: null,
          family_number_2: null,
          certificate: null
        },
        staffs:[],
        cabinas:[],
        search:"",
        current_pagination: 1,
        max_pagination: 0,
        searchStaffID: null,
        searchStaffStatus: '1',
        employeeLangauge,
        language: "English",
        user: {},
        employees_to_sort: [],
        showSpecialStaffs: "false",
        notifications: [],
        selected_emp_id: null,
        documents: [],
        selectedDocument: {},
        selectedDocumentFile: null,
        trigger_download: false
      }
  },
  beforeCreate() {
    auth("employees");
  },
  created(){
    this.$http.post("/getLoggedInfo").then(({data}) => {
        this.user.username = data.username;
        this.user.type = data.type;

        this.$http.post('/notifications/getActiveEmployee').then(({data}) => {
          this.notifications = data;
        }).catch((err) => {
          showError(err);
        });
        
    });
    this.$http.post("/employee/getNoOfEmployees").then(({data}) => {
      this.noOfEmployees = data.noOfEmployees;
      var btns = Math.ceil(this.noOfEmployees / 15);
      var groups = Math.ceil(btns / 5);
      this.max_pagination = groups;
    }).catch((err)=>{
      showError(err);
    });

    this.get_limited_data(1);

    this.$http.post('/staff/getStaffsAndCabinas').then(({data})=>{
      this.staffs = data.staffs.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
      this.employees.staff_name = data.staffs.staff_name;

      this.cabinas = data.cabinas;
    }).catch((err)=>{
      showError(err);
    });

    this.$http.post("/getLoggedInfo").then(({data}) => {
        this.user.username = data.username;
        this.user.type = data.type;
        this.user.en_id = data.en_id;
    });
  },
  mounted() {
    var load_paginations = setInterval(() => {
      var btns_load = document.getElementsByClassName("pg-group-1");
      if(btns_load.length > 0){
        for(let i=0; i < btns_load.length; i++){
          btns_load[i].style.display = 'inline';
        }
        clearInterval(load_paginations);
      }
    }, 750);

    Bus.$on("languageChanged", (data) => {
      this.language = data;
      if(["Kurdish", "Arabic"].includes(data)){
          $(".multi-language").addClass("rtl");
          $("#wrapper").attr("dir", "rtl")
      } else {
          $(".multi-language").removeClass("rtl");
          $("#wrapper").attr("dir", "ltr")
      }
    });
  },
  methods:{
      openUploadFile() {
        this.$refs.inputUploadFile.value = null
        this.$refs.inputUploadFile.click()
      },
      proccessDocuments(event) {
        const files = event.target.files

        if(files && files.length > 0) {
          const f = new FormData();
          for (let i = 0; i < files.length; i++) {
            f.append('documents', files[i])
          }
          this.$http.post('/employee/upload_documents/' + this.selected_emp_id, f).then(({data}) => {
            this.documents = data
            this.$refs.inputUploadFile.value = null
          }).catch((err) => {
            showError(err)
          })
        }
      },
      openPersonalImageInput() {
        this.$refs.personalImage.click()
      },
      processImage(event) {
        if(this.single_employee.emp_id) {
          const f = new FormData();
          f.append('personal_image', event.target.files[0])
          this.$http.post('/employee/updatePersonalImage/' + this.single_employee.emp_id, f).then(({data}) => {
            this.single_employee.personal_image_path = data.personal_image_path
            this.single_employee.imageFile = event.target.files[0]
            this.$refs.personalImage.value = null

            let index = this.employees.findIndex(obj => obj.emp_id == this.single_employee.emp_id)
            this.employees[index].personal_image_path = data.personal_image_path
          }).catch((err) => {
            showError(err);
          })
        } else {
          this.single_employee.imageFile = event.target.files[0]
        }
      },
      next_pagination_btn() {
        if(this.current_pagination < this.max_pagination){
          var btns_to_hide = document.getElementsByClassName("pg-group-" + this.current_pagination);
          for(let i=0; i < btns_to_hide.length; i++){
            btns_to_hide[i].style.display = 'none';
          }
          ++this.current_pagination;
          var btns_to_show = document.getElementsByClassName("pg-group-" + this.current_pagination);
          for(let i=0; i < btns_to_show.length; i++){
            btns_to_show[i].style.display = 'inline';
          }
        }
      },
      previos_pagination_btn() {
        if(this.current_pagination > 1){
          var btns_to_hide = document.getElementsByClassName("pg-group-" + this.current_pagination);
          for(let i=0; i<btns_to_hide.length; i++){
            btns_to_hide[i].style.display = 'none';
          }
          --this.current_pagination;
          var btns_to_show = document.getElementsByClassName("pg-group-" + this.current_pagination);
          for(let i=0; i<btns_to_show.length; i++){
            btns_to_show[i].style.display = 'inline';
          }
        }
      },
      get_limited_data(paginate_number) {
        var offset = 0;
        if(paginate_number > 1){
          offset = (paginate_number - 1) * 15;
        }
        this.$http.post('/employee/getData', {
          offset
        }).then(({data})=>{
            this.employees = data;
        }).catch((err)=>{
            showError(err);
        });
      },
      process_file(event){
        this.single_employee.identification_image_file = event.target.files[0];
      },
      process_file_personal(event){
        this.single_employee.personal_image_file = event.target.files[0];
      },
      clear_employee() {
        this.single_employee.emp_id= null;
        this.single_employee.first_name = null;
        this.single_employee.last_name = null;
        this.single_employee.st_id = null;
        this.single_employee.cabina_id = null;
        this.single_employee.phone = null;
        this.single_employee.birth_date = null;
        this.single_employee.reg_date = null;
        this.single_employee.salary_type = null;
        this.single_employee.monthly_salary = null;
        this.single_employee.daily_salary = null;
        this.single_employee.hour_salary = null;
        this.single_employee.identification_image_path = null;
        this.single_employee.identification_image_file = null;
        this.single_employee.personal_image_path = null;
        this.single_employee.personal_image_file = null;
        this.single_employee.staff_name = null;
        this.single_employee.country = '1';
        this.single_employee.city = null;
        this.single_employee.food_money = 0;
        this.single_employee.transport_money = 0;
        this.single_employee.cabina_money = 0;
        this.single_employee.expense_money = 0;
        this.single_employee.fine_money = 0;
        this.single_employee.loan_money = 0;
        this.single_employee.accomodation_money = 0;
        this.single_employee.other_expense = 0;
        this.single_employee.other_minus = 0;
        this.single_employee.expiry_passport = null;
        this.single_employee.passport_number = null;
        this.single_employee.expire_accomodation = null;
        this.single_employee.accomodation_number = null;
        this.single_employee.asaish_code = null;
        this.single_employee.phone2 = null;
        this.single_employee.car = null;
        this.single_employee.car_number = null;
        this.single_employee.living_location = null;
        this.single_employee.first_work_date = null;
        this.single_employee.job = null;
        this.single_employee.blood_group = null;
        this.single_employee.family_number_1 = null;
        this.single_employee.family_number_2 = null;
        this.single_employee.certificate = null;
        this.single_employee.imageFile = null;
        this.$refs.personalImage.value = null
      },
      get_single_employee(emp_id){
          const [employee] = this.employees.filter(obj => obj.emp_id === emp_id);
          this.single_employee.emp_id = employee.emp_id;
          this.single_employee.first_name = employee.first_name;
          this.single_employee.last_name = employee.last_name;
          // this.single_employee.st_id = this.staffs.filter(obj => obj.staff_name == employee.staff_name)[0].st_id;
          this.single_employee.st_id = employee.st_id;
          this.single_employee.cabina_id = employee.cabina_id;
          this.single_employee.phone = employee.phone;
          this.single_employee.birth_date = employee.birth_date.slice(0,10);
          this.single_employee.reg_date = employee.reg_date;
          this.single_employee.salary_type = employee.salary_type;
          this.single_employee.monthly_salary = employee.monthly_salary;
          this.single_employee.daily_salary=employee.daily_salary;
          this.single_employee.hour_salary = employee.hour_salary;  //edit hour salary
          this.single_employee.identification_image_path = employee.identification_image_path;
          this.single_employee.personal_image_path = employee.personal_image_path;
          this.single_employee.staff_name = employee.staff_name;
          if(employee.expiry_passport && this.isValidDate(employee.expiry_passport)) {
            this.single_employee.expiry_passport = this.organize_date(employee.expiry_passport)
          } else {
            this.single_employee.expiry_passport = null;
          }

          if(employee.expire_accomodation && this.isValidDate(employee.expire_accomodation)) {
            this.single_employee.expire_accomodation = this.organize_date(employee.expire_accomodation)
          } else {
            this.single_employee.expire_accomodation = null;
          }

          if(employee.first_work_date && this.isValidDate(employee.first_work_date)) {
            this.single_employee.first_work_date = this.organize_date(employee.first_work_date)
          } else {
            this.single_employee.first_work_date = null;
          }
          this.single_employee.country = employee.country;
          this.single_employee.city = employee.city;
          this.single_employee.passport_number = employee.passport_number;
          this.single_employee.accomodation_number = employee.accomodation_number;
          this.single_employee.food_money = employee.food_money;
          this.single_employee.transport_money = employee.transport_money;
          this.single_employee.cabina_money = employee.cabina_money;
          this.single_employee.expense_money = employee.expense_money;
          this.single_employee.fine_money = employee.fine_money;
          this.single_employee.loan_money = employee.loan_money;
          this.single_employee.accomodation_money = employee.accomodation_money;
          this.single_employee.other_expense = employee.other_expense;
          this.single_employee.other_minus = employee.other_minus;
          this.single_employee.asaish_code = employee.asaish_code;
          this.single_employee.phone2 = employee.phone2;
          this.single_employee.car = employee.car;
          this.single_employee.car_number = employee.car_number;
          this.single_employee.living_location = employee.living_location;
          this.single_employee.job = employee.job;
          this.single_employee.blood_group = employee.blood_group;
          this.single_employee.family_number_1 = employee.family_number_1;
          this.single_employee.family_number_2 = employee.family_number_2;
          this.single_employee.certificate = employee.certificate;

          if(this.single_employee.personal_image_path) {
            this.$http.get('/employee/personal_image/' + this.single_employee.personal_image_path, {
              responseType: 'arraybuffer'
            }).then(({data}) => {
              const blob = new Blob([data], { type: 'image/*' })
              const file = new File([blob], this.single_employee.personal_image_path)
              this.single_employee.imageFile = file
            }).catch((err) => {
              showError(err);
            });
          } else {
            this.single_employee.imageFile = null
          }
      },
      add_employee(){
        if(!nullValues.includes(this.single_employee.first_name) && !nullValues.includes(this.single_employee.st_id) && !nullValues.includes(this.single_employee.birth_date) && !nullValues.includes(this.single_employee.last_name) && !nullValues.includes(this.single_employee.phone) && !nullValues.includes(this.single_employee.salary_type) ){
          var form= new FormData();
          form.append('first_name', this.single_employee.first_name);
          form.append('last_name', this.single_employee.last_name);
          form.append('st_id', this.single_employee.st_id);
          form.append('cabina_id', this.single_employee.cabina_id);
          form.append('phone', this.single_employee.phone);
          form.append('birth_date', this.single_employee.birth_date);
          form.append('reg_date', this.single_employee.reg_date);
          form.append('salary_type', this.single_employee.salary_type);
          form.append('monthly_salary', this.single_employee.monthly_salary);
          form.append('daily_salary', this.single_employee.daily_salary);
          form.append('hour_salary', this.single_employee.hour_salary);
          form.append('identification_image', this.single_employee.identification_image_file);
          
          form.append('expiry_passport', this.single_employee.expiry_passport);
          form.append('passport_number', this.single_employee.passport_number);
          form.append('expire_accomodation', this.single_employee.expire_accomodation);
          form.append('accomodation_number', this.single_employee.accomodation_number);
          form.append('country', this.single_employee.country);
          form.append('city', this.single_employee.city);
          form.append('food_money', this.single_employee.food_money);
          form.append('transport_money', this.single_employee.transport_money);
          form.append('cabina_money', this.single_employee.cabina_money);
          form.append('expense_money', this.single_employee.expense_money);
          form.append('loan_money', this.single_employee.loan_money);
          form.append('accomodation_money', this.single_employee.accomodation_money);
          form.append('fine_money', this.single_employee.fine_money);
          form.append('other_expense', this.single_employee.other_expense);
          form.append('other_minus', this.single_employee.other_minus);
          if(this.single_employee.asaish_code) {
            form.append('asaish_code', this.single_employee.asaish_code);
          }
          form.append('phone2', this.single_employee.phone2);
          form.append('car', this.single_employee.car);
          if(this.single_employee.car_number) {
            form.append('car_number', this.single_employee.car_number);
          }
          form.append('living_location', this.single_employee.living_location);
          form.append('first_work_date', this.single_employee.first_work_date);
          form.append('family_number_1', this.single_employee.family_number_1);
          form.append('family_number_2', this.single_employee.family_number_2);
          form.append('certificate', this.single_employee.certificate);
          if(this.single_employee.job) {
            form.append('job', this.single_employee.job);
          }

          if(this.single_employee.blood_group) {
            form.append('blood_group', this.single_employee.blood_group);
          }

          if(this.single_employee.imageFile) {
            form.append('personal_image', this.single_employee.imageFile);
          }

          document.getElementById("btn_add_employee").setAttribute("disabled", "disabled");

          this.$http.post('/employee/addEmployee', form).then(({data})=>{
            this.$alert('Successfully', "Success", "success");
            this.employees.unshift({
              emp_id: data.emp_id,
              first_name: this.single_employee.first_name,
              last_name: this.single_employee.last_name,
              st_id: this.single_employee.st_id,
              staff_name: this.staffs.filter(obj => obj.st_id == this.single_employee.st_id)[0].staff_name,
              cabina_id: this.single_employee.cabina_id,
              phone: this.single_employee.phone,
              birth_date :this.single_employee.birth_date,
              reg_date: new Date().toISOString().split("T")[0],
              salary_type: this.single_employee.salary_type,
              monthly_salary: this.single_employee.monthly_salary,
              daily_salary: this.single_employee.daily_salary,
              hour_salary: this.single_employee.hour_salary,
              identification_image_path: data.identification_image_path,
              personal_image_path: data.personal_image_path,
              active_status: "1",
              country: this.single_employee.country,
              city: this.single_employee.city,
              food_money: this.single_employee.food_money,
              transport_money: this.single_employee.transport_money,
              cabina_money: this.single_employee.cabina_money,
              expense_money: this.single_employee.expense_money,
              fine_money: this.single_employee.fine_money,
              loan_money: this.single_employee.loan_money,
              accomodation_money: this.single_employee.accomodation_money,
              other_expense: this.single_employee.other_expense,
              other_minus: this.single_employee.other_minus,
              expiry_passport: this.single_employee.expiry_passport,
              passport_number: this.single_employee.passport_number,
              expire_accomodation: this.single_employee.expire_accomodation,
              accomodation_number: this.single_employee.accomodation_number,
              asaish_code: this.single_employee.asaish_code,
              phone2: this.single_employee.phone2,
              car: this.single_employee.car,
              car_number: this.single_employee.car_number,
              living_location: this.single_employee.living_location,
              first_work_date: this.single_employee.first_work_date,
              blood_group: this.single_employee.blood_group,
              family_number_1: this.single_employee.family_number_1,
              family_number_2: this.single_employee.family_number_2,
              certificate: this.single_employee.certificate
            });
            this.clear_employee();
          }).catch((err) => {
            showError(err);
          }).finally(() => {
            document.getElementById("btn_add_employee").removeAttribute("disabled");
          });
      }
      else{
        this.$alert("Please fill the cells with correct data !", "Empty Field", "warning");
      }
    },
    update_employee(){
        if(!nullValues.includes(this.single_employee.emp_id)){
             if(!nullValues.includes(this.single_employee.first_name) && !nullValues.includes(this.single_employee.st_id) && !nullValues.includes(this.single_employee.birth_date) && !nullValues.includes(this.single_employee.last_name) && !nullValues.includes(this.single_employee.phone) && !nullValues.includes(this.single_employee.salary_type)){
                document.getElementById("btn_update_employee").setAttribute("disabled", "disabled");
                this.$http.patch('/employee/updateEmployee/'+this.single_employee.emp_id, {
                  first_name:this.single_employee.first_name,
                  last_name:this.single_employee.last_name,
                  st_id:this.single_employee.st_id,
                  cabina_id: this.single_employee.cabina_id || null,
                  phone:this.single_employee.phone,
                  birth_date:this.single_employee.birth_date,
                  reg_date:this.single_employee.reg_date,
                  salary_type:this.single_employee.salary_type,
                  monthly_salary:this.single_employee.monthly_salary,
                  daily_salary:this.single_employee.daily_salary,
                  hour_salary:this.single_employee.hour_salary,
                  country: this.single_employee.country,
                  city: this.single_employee.city,
                  food_money: this.single_employee.food_money,
                  transport_money: this.single_employee.transport_money,
                  cabina_money: this.single_employee.cabina_money,
                  expense_money: this.single_employee.expense_money,
                  fine_money: this.single_employee.fine_money,
                  loan_money: this.single_employee.loan_money,
                  accomodation_money: this.single_employee.accomodation_money,
                  other_expense: this.single_employee.other_expense,
                  other_minus: this.single_employee.other_minus,
                  expiry_passport: this.single_employee.expiry_passport,
                  passport_number: this.single_employee.passport_number,
                  expire_accomodation: this.single_employee.expire_accomodation,
                  accomodation_number: this.single_employee.accomodation_number,
                  asaish_code: this.single_employee.asaish_code,
                  phone2: this.single_employee.phone2,
                  car: this.single_employee.car,
                  car_number: this.single_employee.car_number,
                  living_location: this.single_employee.living_location,
                  first_work_date: this.single_employee.first_work_date,
                  job: this.single_employee.job,
                  blood_group: this.single_employee.blood_group,
                  family_number_1: this.single_employee.family_number_1,
                  family_number_2: this.single_employee.family_number_2,
                  certificate: this.single_employee.certificate

                 }).then(()=>{
                    let index = this.employees.findIndex(obj => obj.emp_id === this.single_employee.emp_id);
                    this.employees[index].first_name = this.single_employee.first_name;
                    this.employees[index].last_name = this.single_employee.last_name;
                    this.employees[index].st_id = this.single_employee.st_id;
                    this.employees[index].staff_name = this.staffs.filter(obj => obj.st_id == this.single_employee.st_id)[0].staff_name;
                    this.employees[index].cabina_id = this.single_employee.cabina_id;
                    this.employees[index].phone = this.single_employee.phone;
                    this.employees[index].reg_date = this.single_employee.reg_date;
                    this.employees[index].salary_type = this.single_employee.salary_type;
                    this.employees[index].monthly_salary = this.single_employee.monthly_salary;
                    this.employees[index].daily_salary = this.single_employee.daily_salary;
                    this.employees[index].country = this.single_employee.country;
                    this.employees[index].city = this.single_employee.city;
                    this.employees[index].food_money = this.single_employee.food_money;
                    this.employees[index].transport_money = this.single_employee.transport_money;
                    this.employees[index].cabina_money = this.single_employee.cabina_money;
                    this.employees[index].expense_money = this.single_employee.expense_money;
                    this.employees[index].fine_money = this.single_employee.fine_money;
                    this.employees[index].loan_money = this.single_employee.loan_money;
                    this.employees[index].accomodation_money = this.single_employee.accomodation_money;
                    this.employees[index].other_expense = this.single_employee.other_expense;
                    this.employees[index].other_minus = this.single_employee.other_minus;
                    this.employees[index].expiry_passport = this.single_employee.expiry_passport;
                    this.employees[index].passport_number = this.single_employee.passport_number;
                    this.employees[index].expire_accomodation = this.single_employee.expire_accomodation;
                    this.employees[index].accomodation_number = this.single_employee.accomodation_number;
                    this.employees[index].asaish_code = this.single_employee.asaish_code;
                    this.employees[index].phone2 = this.single_employee.phone2;
                    this.employees[index].car = this.single_employee.car;
                    this.employees[index].car_number = this.single_employee.car_number;
                    this.employees[index].living_location = this.single_employee.living_location;
                    this.employees[index].first_work_date = this.single_employee.first_work_date;
                    this.employees[index].job = this.single_employee.job;
                    this.employees[index].blood_group = this.single_employee.blood_group;
                    this.employees[index].family_number_1 = this.single_employee.family_number_1;
                    this.employees[index].family_number_2 = this.single_employee.family_number_2;
                    this.employees[index].certificate = this.single_employee.certificate;
                    this.$alert('Successfully changed', "Success", "success");
                 }).catch((err) => {
                   showError(err);
                 }).finally(() => {
                   document.getElementById("btn_update_employee").removeAttribute("disabled");
                 });
             }
             else{
                this.$alert("Please fill the cells with correct data !", "Empty Field", "warning");
             }
        }
        else{
          this.$alert("Please select an employee for update !", "", "warning");
        }
    },
    update_identification_image(){
        if(!nullValues.includes(this.single_employee.emp_id)){
          if(!nullValues.includes(this.single_employee.identification_image_file)){
            var form = new FormData();
            form.append('identification_image', this.single_employee.identification_image_file);
            document.getElementById("btn_update_id_image").setAttribute("disabled", "disabled");
            this.$http.patch('/employee/updateIdentificationImage/'+this.single_employee.emp_id,form).then(({data})=>{
              let index=this.employees.findIndex(obj => obj.emp_id === this.single_employee.emp_id);
              this.employees[index].identification_image_path = data.identification_image_path;
              this.single_employee.identification_image_path = data.identification_image_path;
              this.$alert('Successfully changed', "Success", "success");
            }).catch((err)=>{
              showError(err);
            }).finally(() => {
              document.getElementById("btn_update_id_image").removeAttribute("disabled");
            });
          }
          else{
            this.$alert("Please select a image", "No image selected", "warning");
          }
        }
        else{
          this.$alert("Please select an employee for update !", "", "warning");
        }
    },
    update_personal_image(){
        if(!nullValues.includes(this.single_employee.emp_id)){
            if(!nullValues.includes(this.single_employee.personal_image_file)){
                var form=new FormData();
                form.append('personal_image',this.single_employee.personal_image_file);
                document.getElementById("btn_update_personal_image").setAttribute("disabled", "disabled");
                 this.$http.patch('/employee/updatePersonalImage/'+this.single_employee.emp_id,form).then(({data})=>{
                  let index=this.employees.findIndex(obj => obj.emp_id === this.single_employee.emp_id);
                  this.employees[index].personal_image_path=data.personal_image_path;
                  this.single_employee.personal_image_path=data.personal_image_path;
                  this.$alert('Successfully changed', "Success", "success");
                 }).catch((err)=>{
                  showError(err);
                 }).finally(() => {
                  document.getElementById("btn_update_personal_image").removeAttribute("disabled");
                 });
            }
            else{
              this.$alert("Please select a image", "No image selected", "warning");
            }
        }
        else{
          this.$alert("Please select an employee for update !", "", "warning");
        }
    },
    delete_identification_image(){
        if(!nullValues.includes(this.single_employee.emp_id)){
            this.$http.delete('/employee/deleteIdentificationImage/'+this.single_employee.emp_id).then(()=>{
              let index = this.employees.findIndex(obj => obj.emp_id === this.single_employee.emp_id);
              this.employees[index].identification_image_path = null;
              this.single_employee.identification_image_path = null;
              this.$alert('Successfully deleted', "Success", "success");
            }).catch((err)=>{
              showError(err);
            })
          
        }
        else{
          this.$alert("Please select an employee for delete image!", "", "warning");
        }
    },
    delete_personal_image(){
        if(!nullValues.includes(this.single_employee.emp_id)){
            this.$http.delete('/employee/deletePersonalImage/'+this.single_employee.emp_id).then(()=>{
                let index = this.employees.findIndex(obj => obj.emp_id === this.single_employee.emp_id);
                this.employees[index].personal_image_path = null;
                this.single_employee.personal_image_path = null;
                this.$alert('Successfully deleted', "Success", "success");
            }).catch((err)=>{
                showError(err);
            })
        }
        else{
          this.$alert("Please select an employee for delete image!", "", "warning"); 
        }
    },
    active_employee(emp_id){
      this.$confirm("Are you sure to active this employee?","Warning","question").then(()=>{
          this.$http.patch('/employee/activeEmployee/'+emp_id).then(()=>{
            let index = this.employees.findIndex(obj => obj.emp_id === emp_id);
            this.employees[index].active_status = "1";
            this.$alert("Employee Actived",'Active','success');
            let ntf = this.notifications.find(obj => obj.emp_id == emp_id);
            if(ntf){
              this.$alert(ntf.text, "Notification", "info");
            }
          }).catch((err)=>{
              showError(err);
          });
      });
    },
    deactive_employee(emp_id){
     this.$confirm("are you sure to deactive this employee?","Warning","question").then(()=>{
        this.$http.patch('/employee/deactiveEmployee/'+emp_id).then(()=>{
          let index = this.employees.findIndex(obj => obj.emp_id === emp_id);
          this.employees[index].active_status = "0";
          this.$alert("Employee Deactived",'Deactive','success');
        }).catch((err)=>{
            showError(err);
        });
      });
    },
    organize_point(number) {
      var str_num = number.toString();
      var point = str_num.substr(str_num.lastIndexOf('.') + 1, 3);
      if(point == "000"){
        return Number(str_num.substring(0, str_num.lastIndexOf('.')));
      }
      return Number(str_num);
    },
    organize_date(value) {
      if(value == '0000-00-00'){
        return null;
      }
      var d = new Date(value);
      return d.toISOString().split("T")[0];
    },
    deleteImage() {
      const c = confirm("Are you sure to delete this image?");
      if(c) {
        if(this.single_employee.emp_id) {
          this.$http.delete('/employee/deletePersonalImage/' + this.single_employee.emp_id).then(() => {
            this.single_employee.imageFile = null
            this.$refs.personalImage.value = null
            this.single_employee.personal_image_path = null
          }).catch((err) => {
            showError(err);
          })
        } else {
          this.single_employee.imageFile = null
          this.$refs.personalImage.value = null
        }
      }
    },
    search_employee() {
      if(this.search == ""){
        this.$alert("Write a thing to search ...", "Empty Field", "warning");
      } else {
        this.$http.post("/employee/searchEmployee", {
          search_value: this.search
        }).then(({data}) => {
          this.employees = data;
          document.getElementById('paginations').style.display = 'none';
        });
      }
    },
    searchByID(id) {
      this.$http.post("/employee/searchByID", {
          search_value: id
        }).then(({data}) => {
          this.employees = data;
          document.getElementById('paginations').style.display = 'none';
        });
    },
    refresh(){
      this.get_limited_data(1);
      this.search = "";
      document.getElementById('paginations').style.display = 'block';
      this.current_pagination = 1;
    },
    isValidDate(d) {
      let date = new Date(d);
      return date instanceof Date && !isNaN(date);
    },
    sortEmployees(list) {
      return list.sort((a, b) => a.sort_code - b.sort_code)
    },
    saveEmployeesSort() {
      this.$http.post('/employee/saveEmployeesSort', {
        list: this.employees_to_sort
      }).then(() => {
        this.$alert('Successfully', "Success", "success");
      }).catch((err) => {
        showError(err);
      })
    },
    getSortedStaffs(staffs) {
      return staffs.sort((a, b) => a.staff_sort_code - b.staff_sort_code)
    },
    clearDocument() {
      this.$refs.inputUploadFile.value = null
      this.selectedDocument = {}
      this.selectedDocumentFile = null
      this.selected_emp_id = null
    },
    getEmployeeDocuments(emp_id) {
      this.clearDocument()
      this.selected_emp_id = emp_id
      this.$http.get('/employee/get_documents/' + emp_id).then(({data}) => {
        this.documents = data
      }).catch((err) => {
        showError(err)
      })
    },
    async selectDocument(d) {
      this.selectedDocument = JSON.parse(JSON.stringify(d))
      const response = await this.$http.get('/employee/get_document_file/' + d.employee_document + '/' + d.document_type, {
        responseType: 'arraybuffer'
      })
      
      const blob = new Blob([response.data], { type: d.document_type == 'pdf' ? 'application/pdf' : 'image/*' })
      const file = new File([blob], d.employee_document)
      this.selectedDocumentFile = file
      
    },
    deleteDocument(d) {
      const c = confirm("Are you sure to delete this document?");
      if(c) {
        this.$http.delete('/employee/delete_document/' + d.employee_document_id).then(() => {
          this.documents = this.documents.filter(obj => obj.employee_document != d.employee_document)
          this.selectedDocument = {}
          this.selectedDocumentFile = null
        }).catch((err) => {
          showError(err)
        })
      }
    },
    editDocumentName(d) {
      const new_name = prompt("Enter new name")
      if(new_name) {
        this.$http.patch('/employee/edit_document_name/' + d.employee_document_id, {
          employee_document_virtual_name: new_name
        }).then(() => {
          d.employee_document = new_name
          let index = this.documents.findIndex(obj => obj.employee_document_id == d.employee_document_id)
          this.documents[index].employee_document_virtual_name = new_name
        }).catch((err) => {
          showError(err)
        })
      }
    },
    downloadDocument() {
      if(this.selectedDocument.employee_document_id && this.selectedDocumentFile) {
        this.trigger_download = !this.trigger_download
      }
    }
  },
  computed: {
    personalImagePath() {
      if(this.single_employee.imageFile) {
        return URL.createObjectURL(this.single_employee.imageFile);
      } else {
        return this.default_image
      }
    },
    documentUrl() {
      if(this.selectedDocumentFile) {
        return URL.createObjectURL(this.selectedDocumentFile)
      }
      return null
    }
  },
  filters:{
    employees_status_filter(value, language){
      return [
        employeeLangauge[language].deactive,
        employeeLangauge[language].active
      ][value];
    }
  },
  watch: {
    'single_employee.monthly_salary': function(value){
      if(this.single_employee.salary_type == "Monthly"){
        this.single_employee.daily_salary = this.organize_point((Number(value) / 26).toFixed(3));
        this.single_employee.hour_salary = this.organize_point((this.single_employee.daily_salary / 8).toFixed(3));
      }
    },
    'single_employee.daily_salary': function(value){
      if(this.single_employee.salary_type == "Daily"){
        this.single_employee.monthly_salary = this.organize_point((Number(value) * 26).toFixed(3));
        this.single_employee.hour_salary = this.organize_point((this.single_employee.daily_salary / 8).toFixed(3));
      }
    },
    'single_employee.country': function(value){
      if(value == 1){
        this.single_employee.expiry_passport = null;
      }
    },
    searchStaffID(value) {
      if(value) {
        this.$http.post('/employee/getAllEmployeeBystaff/' + value).then(({data}) => {
          this.btnEmployees = data.sort((a, b) => a.sort_code - b.sort_code);
          this.employees_to_sort = this.sortEmployees(data.filter(obj => obj.active_status == '1'));
        })
      } else {
        this.btnEmployees = [];
      }
    },
    trigger_download() {
      if(this.selectedDocument && this.selectedDocumentFile && this.selectedDocument.document_type == 'image') {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(this.selectedDocumentFile)
        
        let donwloadName = this.selectedDocument.employee_document_virtual_name

        if(!(donwloadName.endsWith('.jpg') || donwloadName.endsWith('.jpeg') || donwloadName.endsWith('.png'))) {
          donwloadName = donwloadName + '.' + this.selectedDocument.employee_document.split('.').pop()
        } 
        
        a.download = donwloadName
        a.click()
      }
    }
  }
};
</script>

<style scoped>
  .rtl {
    text-align: right !important;
  }
</style>